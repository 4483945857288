<template>
<div class="bg-primary fixed z-50 w-full h-screen top-0 left-0 flex flex-col md:flex-row justify-center md:justify-around items-center">
  <div class="w-12 h-12 rounded-full fixed top-8 right-8 z-40 flex items-center justify-center filter drop-shadow" @click="$emit('closeMenu')">
    <font-awesome-icon icon="window-close" size="2x" class="text-cream"></font-awesome-icon>
  </div>
  <div class="flex justify-center items-center w-1/4 md:w-1/2 mb-8 md:mb-0">
    <img src="../assets/1x/logo-dark-bg.png" alt="P&D Logo" class="w-40 mt-8 md:mt-0">
  </div>
<div class="text-cream text-2xl w-4/5 md:w-1/2 border-l border-cream pl-4">
    <ul>
      <router-link to="/" @click="$emit('closeMenu')">
        <li>Home</li>
      </router-link>
      <router-link to="column-casing" @click="$emit('closeMenu')">
        <li>Column Casing Installation</li>
      </router-link>
      <router-link to="/sprinkler-casing" @click="$emit('closeMenu')">
        <li>Sprinkler & Service Casing Installation</li>
      </router-link>

      <router-link to="/radiator-guards" @click="$emit('closeMenu')">
        <li>Radiator Guard & Pipework Installation</li>
      </router-link>
      <router-link to="/contact" @click="$emit('closeMenu')">
        <li>Contact Us</li>
      </router-link>
      <router-link to="/careers" @click="$emit('closeMenu')">
        <li>Careers</li>
      </router-link>
      <router-link to="/privacy" @click="$emit('closeMenu')">
        <li>Privacy</li>
      </router-link>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "Menu"
}
</script>

<style scoped>
li {
  @apply text-xl lg:text-2xl my-2 md:my-1 lg:my-4
}
</style>
