<template>
<div>
  <div id="container">
    <div id="inner" class="border-gold border-t-2 border-b-2 bg-cream" ref="colOffset">
      <div class="container w-full mx-auto flex justify-center">
        <div class="px-8">
          <div id="col">
            <img id="img" :src="slide[currentSlide].src" alt="P&D Column Casing">

          </div>
          <div id="text">

            <h2>{{slide[currentSlide].title}}</h2>
            <p>{{slide[currentSlide].text}}</p>
            <router-link :to="slide[currentSlide].link" >
              <button class="mt-4">More Info</button>
            </router-link>
          </div>
        </div>
      </div>

      <div id="nav-left" @click="moveLeft">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 40 100 20" class="w-14 mb-0.5 inline">
          <defs>
            <marker id="left-arrow" markerWidth="10" markerHeight="7"
                    refX="0" refY="3.5" orient="auto">
              <polygon points="0 3.5, 5 0, 5 7" stroke="white" fill="white"/>
            </marker>
          </defs>
          <line x1="0" y1="50" x2="150" y2="50" stroke="#FFF"
                stroke-width="2" marker-start="url(#left-arrow)" />
        </svg>
        {{slide[currentSlide].prev}}
      </div>
      <div id="nav-right" @click="moveRight">
        {{slide[currentSlide].next}}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="40 40 130 20" class="w-20 mb-0.5 inline">
          <defs>
            <marker id="arrowhead" markerWidth="10" markerHeight="7"
                    refX="0" refY="3.5" orient="auto">
              <polygon points="0 0, 5 3.5, 0 7" fill="white" stroke="white" />
            </marker>
          </defs>
          <line x1="0" y1="50" x2="150" y2="50" stroke="#FFF"
                stroke-width="2" marker-end="url(#arrowhead)" />
        </svg>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import cols from '@/assets/1x/columns-gray.png'
import rad from '@/assets/1x/rad-gray.png'
import service from '@/assets/1x/service-gray.png'
import anime from 'animejs'
export default {
  name: "ColumnSection",
  mounted() {
    this.$store.commit('setColSection', this.$refs.colOffset.getBoundingClientRect().y  + window.pageYOffset )

  },
  data(){
    return{
      currentSlide: 0,
      slide:[
        {
          src: cols,
          title: 'Column Casing',
          text:'We install modern, clean encasement solutions to obscure unsightly concrete and steel supporting columns both indoors and out.',
          link:'/column-casing',
          next:'Radiator Guards & Pipe Boxing',
          prev:'Service & Sprinkler Boxing'
        },
        {
          src: rad,
          title: 'Radiator Guards & Pipe Boxing',
          text:'An aesthetic, safe and practical solution to exposed radiators and pipework in high risk environments.',
          link:'/radiator-guards',
          next:'Service & Sprinkler Boxing',
          prev:'Column Casing'
        },
        {
          src: service,
          title: 'Service & Sprinkler Boxing',
          text:'Sprinkler systems, cable work and other infrastructure professionally segregated with bespoke, discrete solutions.',
          link:'/sprinkler-casing',
          next:'Column Casing',
          prev:'Radiator Guards & Pipe Boxing'
        },
      ]
    }
  },
  methods:{
    moveRight(){

      setTimeout(()=>{
        this.currentSlide = this.currentSlide === 2 ? 0 : this.currentSlide + 1;
      },300);

      anime({
        targets: '#img',
        keyframes:[
          {
            translateX: -1500,
            duration: 500,

          },
          {
            translateX: 0,
            duration: 500,

          }
        ]
      });

      anime({
        targets: '#text',
        keyframes:[
          {
            opacity: 0,
            duration: 200,

          },
          {
            delay:500,
            opacity: 1,
            duration: 500
          }
        ]
      });



    },
    moveLeft(){

      anime({
        targets: '#img',
        keyframes:[
          {
            translateX: 1000,
            duration: 900,

          },
          {
            translateX: 0,
            duration: 500,

          }
        ]
      });

      anime({
        targets: '#text',
        keyframes:[
          {
            opacity: 0,
            duration: 200,

          },
          {
            delay:500,
            opacity: 1,
            duration: 500
          }
        ]
      });

      setTimeout(()=>{this.currentSlide = this.currentSlide === 0 ? 2 : this.currentSlide - 1;}, 500);
    },
    automate(){
      this.moveRight();
      setTimeout(this.automate, 5000)
    }
  }
}
</script>

<style scoped>


#container{
  @apply  flex items-center justify-center relative flex-col md:flex-row overflow-hidden mb-12
}

#inner{
  @apply h-196 md:h-96 lg:h-120 w-full flex justify-center items-center relative
}

#col{
  @apply  relative
}

#col img{
  @apply max-w-xs md:max-w-sm lg:max-w-md xl:max-w-2xl md:float-right mx-auto mb-6 md:mb-0 md:pr-8
}
#text{
  @apply md:w-1/2 lg:pb-14 text-center md:text-left px-4 justify-around relative
}

#text h2{
  @apply text-2xl lg:text-3xl lg:text-4xl mb-2 text-primary
}

#text p{
  @apply text-lg lg:text-2xl text-gray-700 xl:w-3/4 mt-2
}

 button{
  @apply relative w-40 bg-gray-100 rounded py-2 text-lg text-gray-900 shadow-lg border border-gray-800
}

#nav-right{
  @apply absolute top-4 lg:top-12 right-4 md:right-12 text-cream bg-primary px-2 py-1 rounded
}

#nav-left{
  @apply absolute bottom-4 lg:bottom-12 left-4 md:left-12 text-cream bg-primary px-2 py-1 rounded
}
</style>
