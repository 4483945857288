<template>
  <div class="w-12 h-12 bg-white rounded-full  fixed top-8 right-8 z-40 flex items-center justify-center filter drop-shadow">
    <font-awesome-icon icon="bars" class="text-primary filter drop-shadow" size="2x" @click="menuOpen=true"></font-awesome-icon>
  </div>
  <transition name="routefade">
    <router-view/>
  </transition>
  <blue-footer></blue-footer>
  <transition name="fade">
    <menu-tab v-if="menuOpen" @closeMenu="menuOpen=false"></menu-tab>
  </transition>
</template>

<script>
import BlueFooter from "@/components/BlueFooter";
import MenuTab from '@/components/Menu'
export default{
  name:'App',
  components: { BlueFooter, MenuTab},
  data(){
    return {
      showSmallLogo: false,
      menuOpen: false
    }
  },
  mounted() {

    window.addEventListener('scroll', () => {

      if ( window.pageYOffset + window.innerHeight > this.$store.state.line1 + window.innerHeight/5 &&  window.pageYOffset < this.$store.state.line1 + (window.innerHeight/3)){
        this.$store.commit('setLineInView', 1);
      }
      if (  window.pageYOffset + window.innerHeight > this.$store.state.line2 + window.innerHeight/5  &&  window.pageYOffset < this.$store.state.line2 + (window.innerHeight/3)){
        this.$store.commit('setLineInView', 2);
      }
      if (  window.pageYOffset + window.innerHeight > this.$store.state.line3 + window.innerHeight/5  &&  window.pageYOffset < this.$store.state.line3 + (window.innerHeight/3)){
        this.$store.commit('setLineInView', 3);
      }
      if (  window.pageYOffset + window.innerHeight > this.$store.state.line4 + window.innerHeight/5  &&  window.pageYOffset < this.$store.state.line4 + (window.innerHeight/3)){
        this.$store.commit('setLineInView', 4);
      }



    })
  }
}

</script>

<style>

.routefade-enter-active,
.routefade-leave-active {
  transition: opacity 2s ease;
}

.routefade-enter-from,
.routefade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


</style>
