<template>
<div>
  <div class="fixed top-0 left-0 w-full h-screen bg-main bg-left lg:bg-center bg-cover bg-no-repeat z-0 top-0" rel="preload"></div>
  <div class="relative z-10">
    <div class="w-full flex flex-col justify-center items-center h-screen">
      <div class="flex flex-col  items-center justify-center w-full lg:w-1/2 px-4 ">
        <div ref="logo" class="w-40 lg:w-64 xl:w-96">
          <img src="../assets/1x/Logo.jpeg" alt="">
        </div>
        <div class="px-2 py-4 w-full md:w-1/2 mt-8 md:mt-4 bg-white bg-opacity-80 border border-primary filter drop-shadow shadow">
          <p class="text-2xl md:text-2xl tracking-wide leading-10 text-center font-sans">Bespoke encasement solutions designed &amp; installed with superior service</p>
        </div>
      </div>
    </div>

    <div id="intro" class="container mx-auto text-lg lg:text-2xl min-h-screen flex justify-center items-center px-8 lg:px-40 lg:leading-10 text-gray-600">
      <div class="flex items-center flex-col">
      <span class="mb-96 mt-40 text-center text-primary border border-primary px-4 py-8 bg-white bg-opacity-90" ref="line1" :class=" $store.state.lineInView === 1 ? 'opacity-100' : 'opacity-0'" >We supply and install encasement and boxing solutions throughout
        the UK</span>
        <span class="mb-96 mt-40 text-center text-primary border border-primary px-4 py-8 bg-white bg-opacity-90" ref="line2" :class=" $store.state.lineInView === 2 ? 'opacity-100' : 'opacity-0'">
        Sourced from specialist manufacturers with high quality solutions
      </span>
        <span ref="line3" class="mb-96 mt-40 text-center text-primary border border-primary px-4 py-8 bg-white bg-opacity-90" :class=" $store.state.lineInView === 3 ? 'opacity-100' : 'opacity-0'">Houses to Housing Associations. Apartments to High Rise</span>
        <span ref="line4" class="mb-96 mt-40 text-center text-primary border border-primary px-4 py-8 bg-white bg-opacity-90ß" :class=" $store.state.lineInView === 4 ? 'opacity-100' : 'opacity-0'">P&amp;D Is The Solution For Encasement Problems</span>
        <span class="my-48"></span>
      </div>
    </div>

  </div>

  <div class="bg-white relative z-10">
    <div ref="col-section">
      <column-section></column-section>
    </div>



    <div id="testimonial" >
      <div>
        <img src="../assets/1x/test-logo.png" alt="A logo from one of our clients" class="mb-12 xl:mb-0">
      </div>
      <div class="px-4 md:px-0 md:w-3/4 md:ml-12 xl:px-12 text-base md:text-lg">
        <p>P&D started working on our sprinkler contract last year, The main work Dave and his team are carrying out for us is the boxing of sprinkler pipe work.
        </p>
        <p>Both myself and my team have set a high expectation regarding the quality of carpentry works on our projects of which P&D constantly excel.
        </p>
        <p>P&Ds Team are polite with the Tenants and maintain a professional approach in their daily work stream, working closely with our TLOs and Site Manager to complete the program and maintain a high level of health and safety.</p>
        <div class="mt-4 font-bold">Ken Sherrock, Senior Site Manager at Fortem Solutions </div>
      </div>
    </div>

    <div id="accreditation" class="flex justify-around items-center w-3/4 lg:container mx-auto pb-48">
      <img src="../assets/1x/cline-gold.png" alt="P&D Constructionline Gold Logo" class="w-1/3 lg:w-auto">
      <img src="../assets/1x/chas.png" alt="P&D CHAS Accredited" class="w-1/3 lg:w-auto">
    </div>
  </div>


</div>
</template>

<script>


import ColumnSection from "@/components/ColumnSection";

export default {
  name: 'Home',
  title: 'P&D - Nationwide Encasement Installers',
  meta: 'Bespoke encasement solutions designed &amp; installed with superior service.  Source from specialist manufacturers, installed with excellence.',
  components: {

    ColumnSection,
  },
  mounted() {
    this.setOffsets()
    window.onresize = this.setOffsets
  },
  methods:{
    setOffsets(){
      this.$store.commit('setLogoOffset', this.$refs.logo.offsetTop )
      this.$store.commit('setLine1', this.$refs.line1.getBoundingClientRect().top + window.pageYOffset )
      this.$store.commit('setLine2', this.$refs.line2.getBoundingClientRect().top  + window.pageYOffset )
      this.$store.commit('setLine3', this.$refs.line3.getBoundingClientRect().top  + window.pageYOffset )
      this.$store.commit('setLine4', this.$refs.line4.getBoundingClientRect().top  + window.pageYOffset )
    }
  }
}
</script>

<style scoped>

.main-btn{
  @apply text-xl lg:text-2xl text-white px-8 py-2 rounded-xl shadow-xl border-white border bg-btn
}



#cc-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}

#rad-image{
  @apply w-full md:w-2/3 absolute bottom-16 md:bottom-20 lg:bottom-60 xl:bottom-8 2xl:bottom-0 right-4 md:right-8 xl:right-0
}

#rad-text{
  @apply pt-80 lg:pt-96 xl:pt-48 2xl:pt-32
}

#rad-text h2{
  @apply mt-16 lg:mt-12 xl:mt-16 ml-44 text-4xl 2xl:text-5xl font-bold
}

#rad-text p{
  @apply leading-7
  text-xl xl:leading-8
  2xl:text-2xl 2xl:leading-9
  pl-44 mt-3 2xl:mt-4
  text-gray-500
}

#rad-mob-text{
  @apply lg:hidden container mx-auto pb-20 -mt-4
}


#rad-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}



h2{
  @apply text-gray-700
}

#service-text{
  @apply pt-20 lg:pt-108 xl:pt-56 2xl:pt-48

}

#service-text h2{
  @apply mt-16 lg:mt-0 xl:mt-16 ml-36 2xl:ml-44 text-4xl 2xl:text-5xl font-bold
}

#service-text p{
  @apply leading-7
  text-xl xl:leading-8
  2xl:text-2xl 2xl:leading-9
  ml-36 2xl:pl-10 mt-2 2xl:mt-4
  text-gray-500
}
#service-image{
  @apply absolute bottom-20 md:bottom-32 lg:bottom-80 xl:bottom-32 2xl:bottom-4 w-full md:w-3/4 md:right-40
}

#service-mob-text{
  @apply lg:hidden container mx-auto pb-20 -mt-8
}


#service-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}

#testimonial{
  @apply flex flex-col xl:flex-row container mx-auto lg:pt-52 pb-20 lg:pb-40  items-center justify-center text-xl leading-8
}

#testimonial p{
  @apply mt-2 italic
}

#intro span {
  @apply transition-all duration-700
}

</style>
