import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import mixin from './mixins'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faPhone, faEnvelope, faWindowClose, faBars } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(faCheck, faPhone, faEnvelope, faWindowClose, faBars)
createApp(App).use(store).use(router).mixin(mixin).component('font-awesome-icon',FontAwesomeIcon).mount('#app')
