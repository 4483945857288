import { createStore } from 'vuex'

export default createStore({
  state: {
    logoOffset:0,
    footerOffset:0,
    line1:0,
    line2:0,
    line3:0,
    line4:0,
    lineInView:0,
    colOffset:0,
    bgTop:0
  },
  mutations: {
    setLogoOffset(state, offset){
      state.logoOffset = offset
    },
    setFooterOffset(state, offset){
      state.footerOffset = offset
    },
    setLine1(state, offset){
      state.line1 = offset
    },
    setLine2(state, offset){
      state.line2 = offset
    },
    setLine3(state, offset){
      state.line3 = offset
    },
    setLine4(state, offset){
      state.line4 = offset
    },
    setLineInView(state, offset){
      state.lineInView = offset
    },
    setColSection(state, offset){
      state.colOffset = offset
    },
    setBgTop(state, top){
        state.bgTop = top;
    }
  },
  actions: {
  },
  modules: {
  }
})
