<template>
<div class="w-full bg-primary relative z-20" ref="footer">
<div class="flex-col md:flex-row flex justify-around items-center mb-16 md:mb-0 pt-8">
  <div class="text-cream">
    <ul>
      <router-link to="/">
        <li>Home</li>
      </router-link>
      <router-link to="column-casing">
        <li>Column Casing Installation</li>
      </router-link>
      <router-link to="/sprinkler-casing">
        <li>Sprinkler & Service Casing Installation</li>
      </router-link>

      <router-link to="/radiator-guards">
        <li>Radiator Guard & Pipework Installation</li>
      </router-link>
      <router-link to="/contact">
        <li>Contact Us</li>
      </router-link>
      <router-link to="/careers">
        <li>Careers</li>
      </router-link>
      <router-link to="/privacy">
        <li>Privacy</li>
      </router-link>
    </ul>
  </div>
  <img src="../assets/1x/logo-dark-bg.png" alt="P&D Logo" class="w-40 mt-8 md:mt-0">
</div>
  <div class="text-white w-full text-center pb-2">
    P AND D (Worcester) Limited. Company Number 11131844. Image credit: <a href="https://www.pendock.co.uk/" target="_blank">Pendock</a>. &copy; 2021 onward
  </div>
  <div class="w-full text-white text-center text-sm">
    <a href="mailto:go@getdane.co.uk" class="underline inline-block my-2">Built By Dane</a>
  </div>
</div>
</template>

<script>
export default {
  name: "BlueFooter",
  mounted() {
    setTimeout(()=>{this.$store.commit('setFooterOffset', this.$refs.footer.offsetTop)}, 1000)
  }
}
</script>

<style scoped>
li{
  @apply font-sans text-lg mt-2
}
</style>
